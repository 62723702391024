<template>
  <div class="system-config">
    <main-tab-bar :tabitem="tabitem">
      <div :slot="currentNav.name">
        <div class="system-conf-form" style="width: 100%" v-loading="loading">
          <el-form
            :model="systemConfForm"
            label-position="right"
            label-width="120px"
            size="mini"
            status-icon
            style="width: 65%"
            :rules="rules"
            ref="systemConfForm"
          >
            <el-row :gutter="20">
              <el-col :sm="24" :md="24" :lg="24" :xl="24" :offset="0">
                <!-- 小程序标题 start @author: HDZ !-->
                <el-form-item label="小程序标题：" prop="name">
                  <el-input
                    v-model="systemConfForm.name"
                    placeholder="由中、英文字符、数字、“+”号组成"
                  ></el-input>
                </el-form-item>
                <el-form-item label="小程序标识：" prop="identify">
                  <el-input v-model="systemConfForm.identify"></el-input>
                </el-form-item>
                <el-form-item label="appid:" prop="appid">
                  <el-input v-model="systemConfForm.appid"></el-input>
                </el-form-item>
                <el-form-item label="secret：" prop="secret">
                  <el-input v-model="systemConfForm.secret"></el-input>
                </el-form-item>
                <el-form-item label="票务系统:">
                  <el-select
                    placeholder="请选择票务系统"
                    @change="ticketValueChange()"
                    v-model="systemConfForm.ticketitype_id"
                    clearable
                  >
                    <el-option
                      v-for="(item, i) in ticketData"
                      :key="i"
                      :label="item.name"
                      :value="item.row_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="腾讯地图key:" prop="tmapkey">
                  <el-input v-model="systemConfForm.tmapkey"></el-input>
                </el-form-item>
                <!-- <el-form-item label="媒体资源地址：" prop="media_path">
                  <el-input v-model="systemConfForm.media_path"></el-input>
                </el-form-item>
                <el-form-item label="静态资源包：" prop="static_path">
                  <el-input v-model="systemConfForm.static_path"></el-input>
                </el-form-item> -->
                <el-form-item label="是否免登陆：">
                  <el-switch
                    v-model="systemConfForm.is_open"
                    active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item label="底部菜单风格:">
                  <!-- <el-select
                    placeholder="请选择风格"
                    @change="styleChange"
                    v-model="systemConfForm.style"
                  >
                    <el-option
                      v-for="(item, i) in styleData"
                      :key="i"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select> -->
                  <!-- 自定义组件中使用v-mode指令 -->
                  <StyleSelector
                    :styleName.sync="systemConfForm.style"
                  ></StyleSelector>
                </el-form-item>
                <el-form-item label="签到积分：">
                  <el-input-number
                    v-model="systemConfForm.checkpoints"
                    :min="1"
                    :max="100"
                  ></el-input-number>
                  <span style="color:#606266;margin-left:10px">积分</span>
                </el-form-item>
                <el-form-item label="支付积分：">
                  <el-input-number
                    v-model="systemConfForm.yuanpoints"
                    :min="0"
                    :max="100"
                  ></el-input-number>
                  <span style="color:#606266;margin-left:10px">积分/元</span>
                </el-form-item>
                <el-form-item label="充值设置:" >
                  <el-input v-model="systemConfForm.recharge"></el-input>
                </el-form-item>
                <el-form-item label="logo：">
                  <!-- 图片上传 -->
                  <image-upload
                    :imgsrc="systemConfForm.logo ? systemConfForm.logo : ''"
                    filedname="logo"
                  ></image-upload>
                </el-form-item>
                <!--
                <el-form-item>
                  <kind-editor></kind-editor>
                </el-form-item> -->

                <el-form-item label="备注：">
                  <el-input
                    v-model="systemConfForm.remark"
                    type="textarea"
                    size="medium"
                    rows="4"
                    class="textareaNOPut"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button type="primary" @click="clickSave('systemConfForm')"
                >保存</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </main-tab-bar>
  </div>
</template>
<script>
//标签页组件
import MainTabBar from "@components/content/maintabbar/MainTabBar";
import ImageUpload from "../modelmanage/childecomponent/ImageUpload";
import KindEditor from "@components/common/editor/KindEditor";
import StyleSelector from "@components/StyleSelector/index";
import { uploadMinIo, removeObject } from "@minio/minio";
export default {
  name: "SystemConfig",
  props: {},
  components: {
    MainTabBar,
    ImageUpload,
    KindEditor,
    StyleSelector,
  },
  data() {
    // 自定义规则
    var miniProgramTitlePass = (rule, value, callback) => {
      let str = value.split("");
      if (str.indexOf("+") != -1 && str.indexOf("+") != str.length - 1) {
        callback(new Error("+号必须在末尾！"));
      } else {
        callback();
      }
    };

    return {
      tabitem: [
        {
          name: "sjy", //移除时使用
          label: "",
        },
      ],
      currentNav: {},
      // form表单
      systemConfForm: {
        name: "", // 小程序标题
        identify: "", // 小程序标识
        appid: "", // 小程序appid
        secret: "", // 小程序secret
        tmapkey: "", // 小程序tmapkey
        media_path: "", // 媒体资源地址
        static_path: "", // 静态资源包
        is_open: 1, // 是否开启
        style: "",
        logo: "",
        remark: "", // 备注
        ticketitype_id: "", //票务系统ID
        ticketitype_code: "", //票务系统编码
      },
      //票务系统信息
      ticketData: [],
      // 样式数据
      styleData: [
        {
          value: "basicStyle",
          label: "默认风格",
        },
        {
          value: "style2",
          label: "风格2",
        },
        {
          value: "style3",
          label: "风格3",
        },
      ],
      // 验证规则
      rules: {
        // 小程序标题
        name: [
          { required: true, message: "请输入小程序标题！", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符(中文为2个字符)",
            trigger: "blur",
          },
          { validator: miniProgramTitlePass, trigger: "blur" },
        ],
        // 小程序标识
        identify: [
          { required: true, message: "请输入小程序标识！", trigger: "blur" },
        ],
        // appid: [
        //   { required: true, message: "请输入小程序appid！", trigger: "blur" },
        // ],
        // secret: [
        //   { required: true, message: "请输入小程序secret！", trigger: "blur" },
        // ],
        // 媒体资源包
        // media_path: [
        //   {
        //     required: true,
        //     message: "请输入媒体资源包地址！",
        //     trigger: "blur",
        //   },
        // ],
      },
      formData: [],
      loading: true,
      isDelImgFromMinio: true, // 是否从minio删除图片，处理上传后不保存的行为
    };
  },
  created() {
    var file = require("@assets/images/title_logo.png");
    //uploadMinIo(file);
    this.$bus.$off("imgUploaded", this.imgUploaded);
    this.$bus.$off("imgRemoved", this.imgRemoved);
  },
  mounted() {
    this.activename = "sjy";
    this.currentNav = this.$store.state.currentNav;
    this.$set(this.tabitem[0], "label", this.currentNav.name);
    //this.getResourcefield();
    //接收事件总线
    this.$bus.$on("imgUploaded", this.imgUploaded);
    this.$bus.$on("imgRemoved", this.imgRemoved);
    let params = {
      //data: {},
      directory_code: this.$store.state.currentNav.index,
    };
    this.queryDataOfResource(params);
    let paramss = {
      //data: {},
      directory_code: "amt_interface",
    };
    this.queryDataInterface(paramss);
    console.log(this.systemConfForm);
  },
  beforeDestroy() {
    console.log("离开页面");
    if (this.isDelImgFromMinio && this.systemConfForm.logo != "") {
      removeObject("medialibrary", this.getImgName(this.systemConfForm.logo))
        .then((res) => {
          console.log("未保存，上传的图片已删除！");
          this.$message({
            message: "未保存，上传的图片已删除！",
            type: "error",
          });
        })
        .catch((err) => {});
    }
  },

  methods: {
    //保存
    clickSave(formName) {
      console.log(formName);
      this.isDelImgFromMinio = false;
      console.log("this.systemConfForm", this.systemConfForm);
      this.$refs[formName].validate((valid) => {
        //表单验证
        if (valid) {
          var formc = this.clearSubmitData(this.systemConfForm); //删除空的字段
          formc.is_open = formc.is_open == true ? 1 : 0;
          formc.logo = formc.logo ? formc.logo : "";
          var params = {
            data: formc,
            directory_code: this.$store.state.currentNav.index,
          };

          //判断数组是否为空，为空的话则为新增账号，否者为修改数据
          if (this.formData.length == 0) {
            //新增表单
            this.addDataOfResource(params);
            // 重置表单
            //this.$refs[formName].resetFields();
          } else {
            // formc.row_id = this.formData[0].row_id;
            // 修改表单
            this.modifyDataOfResource(params);
          }
        } else {
          this.$message.error("请正确输入表单!!");
          return false;
        }
      });
    },
    queryDataOfResource(params) {
      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((res) => {
          this.loading = false;
          console.log("系统", res);
          if (res.errcode == 0) {
            if (res.data[0].logo != "") {
              this.isDelImgFromMinio = false;
            }
            this.systemConfForm = res.data[0];
            this.systemConfForm.is_open =
              this.systemConfForm.is_open == 1 ? true : false;
            this.formData = res.data[0];
            console.log("formData:", this.formData);
          }
        })
        .catch();
    },
    addDataOfResource(params) {
      this.$Request({
        method: "post",
        url: this.$Api.addDataOfResource,
        // headers: {'Content-Type': 'application/json'},
        data: params,
      })
        .then((res) => {
          console.log("小程序信息添加:", res);
          if (res.errCode == 0) {
            this.$message({
              type: "success",
              message: "添加成功!",
            });
            //this.loading =
          }
        })
        .catch((err) => {});
    },
    //修改信息
    modifyDataOfResource(params) {
      this.$Request({
        method: "post",
        url: this.$Api.modifyDataOfResource,
        // headers: {'Content-Type': 'application/json'},
        data: params,
      })
        .then((res) => {
          console.log("小程序信息修改:", res);
          if (res.errCode == 0) {
            this.$message({
              type: "success",
              message: "修改成功!",
            });
            this.loading = true;
            this.queryDataOfResource({ directory_code: "amt_basicconfig" });
          }
        })
        .catch((err) => {
          // console.log('修改失败',err)
        });
    },
    //获取票务系统信息
    queryDataInterface(params) {
      console.log("获取票务系统信息");
      this.$Request({
        method: "get",
        url: this.$Api.queryDataOfResource,
        params,
      })
        .then((res) => {
          console.log("票务系统", res.data);
          // this.ticketData = [
          //   { row_id: 0, name: "无", ticketitype_code: "" },
          //   ...res.data,
          // ];
          this.ticketData = res.data
        })
        .catch();
    },
    //票务系统信息改变
    ticketValueChange() {
      // console.log(this.ticketValue,'ticketValueChange');
      this.ticketData.forEach((item) => {
        if (item.row_id == this.systemConfForm.ticketitype_id) {
          this.systemConfForm.ticketitype_code = item.code;
        } else {
          this.systemConfForm.ticketitype_code = "";
        }
      });
    },
    //样式信息改变
    styleChange(val) {
      this.systemConfForm.style = val;
    },
    //处理上传前的表单  int类型 为空都写为0 删除create_time
    clearSubmitData(fromData) {
      delete fromData.create_time;
      //删除空字段
      Object.keys(fromData).map((item) => {
        // let isint = false;
        // if (this.fieldMap.hasOwnProperty(item)) {
        //   isint = this.fieldMap[item].indexOf("int") != -1;
        // }
        if (
          !fromData[item] &&
          item != "remark" &&
          item != "ticketitype_code" &&
          item != "ticketitype_id" &&
          item != "recharge"
        ) {
          delete fromData[item];
        }
        return true;
      });
      return fromData;
    },
    imgUploaded(res, name) {
      console.log(name, res);
      this.$set(this.systemConfForm, name, res.path);
      //  this.form[name] = res.path
    },
    imgRemoved(name) {
      // console.log(name);
      this.systemConfForm[name] = "";
    },
    // 获取图片路径
    getImgName(url) {
      if (url) {
        let index1 = this.findCharIndex(url, "/", 3);
        console.log("index1", index1);
        let index2 = url.lastIndexOf("?");
        console.log("index2", index2);
        url =
          index2 != -1
            ? url.substring(index1 + 1, index2)
            : url.substring(index1 + 1, url.length);
        return url;
      }
    },
    // 获取某个字符在一串字符中第n次出现的位置（注意：从0开始计数）
    findCharIndex(str, cha, num) {
      let x = str.indexOf(cha);
      for (let i = 0; i < num; i++) {
        x = str.indexOf(cha, x + 1);
      }
      return x;
    },
  },
};
</script>

<style  scoped lang="scss">
.system-conf-form {
  padding: 15px;
  padding-left: 50px;
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;
  background-size: cover;
}
/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
}
/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 3px;
  //padding-left: 10px;
}
//滚动条的滑块
::-webkit-scrollbar-thumb {
  background-color: #fafbfc;
  border-radius: 3px;
}
.el-form-item {
  margin-bottom: 14px !important;
}
</style>